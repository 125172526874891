import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Select from '../../components/Form/Select';
import Form from '../../components/Form/Form';
import * as util from '../../utilities/index';

const API_BASE = "https://data.police.uk/api"; // TODO :: Put this and the queryconfig in its own file.

const APIForm = ({
    query,
    setQuery,
    isFetching,
    abort,
    setAbort,

    // Arrays of options to pass into the query form on the query menu.
    archivedSearches,

    // Arrays of selected options for the query form on the query menu.
    selectedSearchInfo,
    selectedSearch,

    // State functions for the query form to use to set the new values when selected.
    setSelectedSearchInfo,
    setSelectedSearch,
    setArchivedSearches,

    // Search function
    handleSearch,
}) => {
    // Form helper functions


    const getSearchOptions = () => {
        if (archivedSearches) {
            return archivedSearches.map((option, index) => {
                console.log("getSearchOptions :: archivedSearches :: option = ", option);
                if ( "info" in option ) {
                    if ( "id" in option && "label" in option.info ) {
                        return {
                            key: `${index}`,
                            value: `${option.id}`,
                            label: `${option.info.label}`,
                        };
                    }
                }
            });
        }
    };
    let SearchOptions = getSearchOptions();

    let queryConfig = [
        {
            id: "archived-searches",
            api: "archived-searches",
            label: "Choose a saved search",
            // apiCall: "getCrimeReports(category, force, date)",
            apiValues: {
                selectedSearch: selectedSearch,
            },

            apiCall(queryobj) {
                let callsArray = [];
                
                return callsArray;
            },
            
            form: {
                formOnSubmit: handleSearch, // handleSearchCrimesNoLocation, // handleSearch,
                fields: [
                    {
                        type: "select",
                        label: "Choose a saved search",
                        id: "search",
                        name: "search",
                        required: true,
                        value: selectedSearch,
                        onChange: setSelectedSearch,
                        disabled: { isFetching },
                        multiple: "multiple",
                        unsetOption: "Select Search*",
                        options: [
                            ...SearchOptions,
                        ],
                    },
                ],
            },
        },
    ];

    return (
        <div className="query-form-container">
            <div className="query-form" key="query-form-query">
                <form className="form-container"  key="query-form-query-form-container">
                    <Select
                        height={50}
                        width={100}
                        label="Select a query"
                        id={`querySelect`}
                        name={`querySelect`}
                        value={query.id ?? ""}
                        unsetOption={`Select Query`}
                        optionsConfig={queryConfig.map((queryInfo, index) => {
                            return {
                                key: `${index}`,
                                value: `${queryInfo.id}`,
                                label: `${queryInfo.label}`,
                            };
                        })}
                        disabled={isFetching}
                        onChange={
                            (selected) => {
                                console.log(
                                    "Query selected: ",
                                    selected,
                                    queryConfig.filter(
                                        (value) => value.id === selected,
                                    )[0],
                                );
                                setQuery(
                                    queryConfig.filter(
                                        (value) => value.id === selected,
                                    )[0],
                                );
                            }
                            // setQuery
                        }></Select>
                </form>
            </div>
            <hr className="hr-section-border" />
            <div className="query-form" key="query-form-fetch">
                {query && queryConfig && (
                    <Form
                        key="query-form-fetch-form-container"
                        name={`APIForm`}
                        formID={`APIForm`}
                        onSubmit={
                            queryConfig.filter(
                                (value) => value.id === query.id,
                            )[0].form.formOnSubmit
                        }
                        model={
                            queryConfig.filter(
                                (value) => value.id === query.id,
                            )[0].form
                        }
                        disabled={isFetching}
                    ></Form>
                )}
                {query && queryConfig && isFetching && (
                    <button
                        key={`abort-query-button`}
                        id={`abort-query-button`}
                        className={`button header-nav-button ${
                            abort === true ? "active" : ""
                        }`}
                        onClick={(event) => {
                            if (abort === true) {
                                setAbort(false);
                            } else if (abort === false) {
                                setAbort(true);
                            }
                        }}>
                        Abort Query
                    </button>
                )}
            </div>
        </div>
    );
};

export default APIForm;
