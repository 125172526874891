// React
import React, { useState, useEffect, useRef } from "react";

// Redux state management
import { useDispatch, useSelector } from 'react-redux';
import {
    SetMenu,
    SetTheme,
    SetEnvironment,
    SetDebug,
    SetLoading,
    SetFetching,
    SetCache,
    ReloadData,
} from '../../redux/rootSlice';

// Components
import APIForm from "./APIForm";
import Header from '../../components/Page/Header/Header';
import Sidebar from "../../components/Page/Sidebar/Sidebar";
import Content from "../../components/Page/Content/Content";
import Tabs from "../../components/Tabs/Tabs";
import Table from '../../components/Table/Table';
import Droplist from '../../components/Droplist';

// Utility handler imports.
import * as api from '../../api';
import * as ENV_VARS from "../../global/env";
import * as util from '../../utilities/index';
// import { geoObj2geoArray } from "./Utilities/GeoUtilities";
// import * as gutil from "./Utilities/GeoUtilities";
// import * as util from "./Utilities/index";

function APIDashboard ( props )
{
    const {
        progressInfo,
        setProgressInfo,
        showSidebar,
        setShowSidebar,
    } = props;
    const dispatch = useDispatch();
    const {
        menu,
        theme,
        environment,
        debug,
        loading,
        fetching,
        cache,
        reloadData,
    } = useSelector(state => state.root);

    // Search handling data.
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [abort, setAbort] = useState(false);
    const [error, setError] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [errorLog, setErrorLog] = useState([]);
    const [ queryString, setQueryString ] = useState( "" ); // String for the table downloader to use for its filename. It util.ao.has to be set all the way up here!
    
    // Side panel stuff.
    const [showSidePanel, setShowSidePanel] = useState(false);
    const [sidePanelID, setSidePanelID] = useState("");
    const [sidePanelData, setSidePanelData] = useState([]);

    // Content management flags.
    const [showContent, setShowContent] = useState(true);
    const [showTable, setShowTable] = useState(false);
    // Drawn areas will be an array containing objects, each containing arrays of coordinate pairs describing an area.
    const [ archivedSearches, setArchivedSearches ] = useState(
        [
            {
                id: 0,
                index: 0,
                info: {
                    label: "Search 1",
                    date_created: "2023-09-24",
                    url: "https://data.police.uk/api/crimes-no-location?category=all-crime&force=avon-and-somerset&date=2022-1",
                    parameters: {
                        category: "all-crime",
                        date: "2021-3",
                        force: "avon-and-somerset",
                    },
                    data_type: "data",
                    last_queried: "2023-09-24",
                    num_results: 31172,
                    last_results: [
                        {
                            index: 0,
                            data: []
                        },
                        {
                            index: 1,
                            data: []
                        },
                    ],
                },
            },
            {
                id: 1,
                index: 1,
                info: {
                    label: "Search 2",
                    date_created: "2023-09-24",
                    url: "https://data.police.uk/api/crimes-no-location?category=all-crime&force=avon-and-somerset&date=2022-1",
                    parameters: {
                        category: "all-crime",
                        date: "2021-3",
                        force: "avon-and-somerset",
                    },
                    data_type: "data",
                    last_queried: "2023-09-24",
                    num_results: 31172,
                    last_results: [
                        {
                            index: 0,
                            data: []
                        },
                        {
                            index: 1,
                            data: []
                        },
                    ],
                },
            },
        ]
    );
    const [ selectedSearch, setSelectedSearch ] = useState( "" );
    const [ selectedSearchInfo, setSelectedSearchInfo ] = useState( [] );

    const [dates, setDates] = useState(util.time.generateDateOptions());
    const [date, setDate] = useState([]); // = useState([`2022-9`]);

    const getButtons = (options) => {
        let buttons = [];
        options.forEach((item, index) => {
            // console.log( "Building buttons :: #", index, " = ", item );
            if (item) {
                if ("info" in item) {
                    if ("label" in item.info) {
                        // console.log( "Building buttons :: #", index, " :: ", "id = ", item.id, " :: ", "label = ", item.info.label );
                        buttons.push(
                            <button
                                className="map-sidebar-button"
                                id={`sidebar-button-${item.id}`}
                                key={`sidebar-button-${item.id}`}
                                onClick={(event) => {
                                    setSelectedSearch(item.id);
                                    setSelectedSearchInfo(item);
                                }}
                            >
                                {item.info.label}
                            </button>,
                        );
                    }
                }
            }
        });
        return buttons;
    };

    // Useeffect for handling clicking on an archived search.
    useEffect(() => {
        console.log("selectedSearch has changed :: selectedSearch id = ", selectedSearch);
        if (selectedSearch && selectedSearch !== " ") {
            
            console.log(
                "selectedSearch = ",
                selectedSearch,
                ", selectedSearchInfo = ",
                selectedSearchInfo,
            );
            // setSidePanelData([{ data: "No data provided." }]);
            // setSelectedSearchInfo( item.info.id );
            for (const savedSearch of archivedSearches) {
                if (savedSearch) {
                    let id;
                    console.log(
                        "savedSearch = ",
                        savedSearch
                    );
                    if (typeof savedSearch === "object") {
                        if ("id" in savedSearch) {
                            if ( savedSearch.id === selectedSearch ) {
                                setSelectedSearchInfo( savedSearch );
                            }
                            id = savedSearch.id;
                        }
                    } else {
                        id = savedSearch;
                    }
                }
            }
        } else {
            
        }
    }, [selectedSearch]);

    // Useeffect when selectedSearchInfo changes.
    useEffect(() => {
        console.log("selectedSearchInfo has changed :: selectedSearchInfo = ", selectedSearchInfo);
        
        setIsFetching(false);
        setIsLoading(false);
        setShowTable(true);
        setShowContent(true);

    }, [selectedSearchInfo]);

    const getFormattedOptions = (
        selectedOptions,
        allOptions,
        optionObjectKey = 'id',
        selectAllOption = 'all'
    ) => {
        if (
            util.val.isValidArray(allOptions, true) &&
            (selectedOptions === selectAllOption ||
                selectedOptions.toString().includes(selectAllOption))
        ) {
            // Get only the date keys in the YYYY-MM format, not their labels.
            return allOptions
                .filter(optionObject => {
                    return (
                        optionObject[optionObjectKey] !== undefined &&
                        optionObject[optionObjectKey] !== selectAllOption
                    );
                })
                .map(optionObject => optionObject[optionObjectKey]);
        } else {
            // Get only the date keys in the YYYY-MM format, not their labels.
            if (Array.isArray(selectedOptions)) {
                return selectedOptions.filter(option => {
                    return option !== undefined && option !== selectAllOption;
                });
            } else {
                return [selectedOptions.toString()];
            }
        }
    };

    // Mainly used for debug, use this to update the text outputs for all the changes in state.
    useEffect(() => {
        console.log(
            "APIDashboard :: \n\n",
            "\n",
            "query = ",
            query,
            "\n",
            "date = ",
            date,
            "\n",
            "selectedSearch = ",
            selectedSearch,
            "\n",
            "selectedSearchInfo = ",
            selectedSearchInfo,
            "\n",
            "isFetching = ",
            isFetching,
            "\n",
            "showContent = ",
            showContent,
            "\n",
            "showTable = ",
            showTable,
            "\n",
            "queryString = ",
            queryString,
        );
    }, [
        query,
        isFetching,
        showContent,
        showTable,
        queryString,
        selectedSearch,
        selectedSearchInfo,
    ]);

    // Useeffect for handling the side panel
    useEffect(() => {
        console.log("SidePanelID = ", sidePanelID);
        if (sidePanelID && sidePanelID !== " ") {
            Promise.all([api.getCrimeOutcomes(sidePanelID)])
                .then(([sidePanelData]) => {
                    setSidePanelData(sidePanelData);
                    setIsLoading(false);
                    console.log(
                        "SidePanelID = ",
                        sidePanelID,
                        ", sidePanelData = ",
                        sidePanelData,
                    );
                })
                .then(() => {
                    setShowSidePanel(true);
                })
                .catch((error) => setError(error))
                .then(() => setIsLoading(false));
        } else {
            setSidePanelData([{ data: "No data provided." }]);
        }
    }, [sidePanelID]);

    useEffect(() => {
        console.log("APIDashboard :: \n\n", "\n", "query = ", query);
        // Query changed. Check what type of data the map needs and change the mapDatatype.
        if (query) {
            if ("apiValues" in query) {
                // if ( "coordinates" in query.apiValues )
                // {
                //     setSelectedAreas( [] );
                //     setMapDatatype("point");
                // } else if ("areas" in query.apiValues) {
                //     setCoordinates( [] );
                //     setMapDatatype("polygon");
                // }
            }
        }
    }, [ query ] );
    
    const handleSearch = async (event) => {
        event.preventDefault();
        let src = `APIDashboard :: handleSearch`;
        console.log(
            `${src} triggered :: `,
            date,
            `, query = `,
            query,
        );
        // console.log(`${src} triggered :: `, force, date, `, query = `, query, query.apiValues, query.apiCall(), event, event.target, event.target.date.value, event.target.force.value);

        setIsFetching(true);
        setShowTable(false);
        setShowContent(true);

        // Clear the table.
        setTimeout(() => {
            setSearchResults([]);
        }, 1000);

        setShowTable(true);
        // setProgressInfo([{ message: "", currValue: 0 }]);
        setIsFetching(false);

        // Finally, update the local DB with the query vars, the query results, and the query error log.
    };

    return (
        <>
            <Sidebar
                isFetching={isFetching}
                showSidebar={showSidebar}
            >
                {
                    <APIForm
                        query={query}
                        setQuery={setQuery}
                        isFetching={isFetching}
                        abort={abort}
                        setAbort={setAbort}
                        // Arrays of options to pass into the query form on the query menu.
                        archivedSearches={archivedSearches}
                        // Arrays of selected options for the query form on the query menu.
                        selectedSearch={selectedSearch}
                        selectedSearchInfo={selectedSearchInfo}
                        // State functions for the query form to use to set the new values when selected.
                        setSelectedSearchInfo={setSelectedSearchInfo}
                        setSelectedSearch={setSelectedSearch}
                        setArchivedSearches={setArchivedSearches}
                        // Search function
                        handleSearch={handleSearch}
                    >
                    </APIForm>
                }
                <div
                    className="button-list-container"
                    id="sidebar-buttons-container"
                >
                    <h2 className="button-list-label">Saved Searches:</h2>
                    {
                        getButtons(archivedSearches)
                    }
                </div>
                <div className="flex-panel">
                    {util.val.isValidArray(selectedSearchInfo, true) && (
                        <div className="flex-panel-element">
                            <Droplist
                                data={[selectedSearchInfo]}
                                label={`Areas Selected`}
                            ></Droplist>
                            <div>
                                <h4>Search selected:</h4>
                                <h6>{ selectedSearchInfo.info.label }</h6>
                            </div>
                            <div>
                                { util.dom.valToList([selectedSearchInfo]) }
                            </div>
                        </div>
                    )}
                </div>
            </Sidebar>

            <div className="page-content">
                <Header
                    showSidebar={showSidebar}
                    toggleSidebar={() => setShowSidebar(!showSidebar)}
                    showTitle={true}
                />
                <Content
                    isFetching={isFetching}
                    isLoading={isLoading}
                    progressInfo={progressInfo}
                    showContent={showContent}
                >
                    {
                        (
                            <Tabs
                                type="top"
                                fillArea={true}
                                centered={true}
                                padContent={false}
                                roundedNav={false}
                            >
                                <div
                                    className=""
                                    label="Selected Searches Info View"
                                    id="selected-search-info-view"
                                >
                                    {!util.val.valIsValid(selectedSearchInfo) && 
                                        <h4>Nothing selected</h4>
                                    }

                                    {util.val.valIsValid(selectedSearchInfo) && (
                                            <div className="flex-panel">
                                                <Droplist
                                                    data={selectedSearchInfo}
                                                    label={`Search Selected`}
                                                ></Droplist>
                                                { util.dom.obj2List(selectedSearchInfo) }
                                            </div>
                                    )}
                                </div>
                                
                                {util.val.valIsValid([selectedSearchInfo]) &&
                                    showTable && (
                                        <div
                                            className=""
                                            label="Datatable Results View"
                                            id="query-dashboard-datatable-view"
                                        >
                                            <Table
                                                // isVisible={showTable}
                                                isFetching={isFetching}
                                                isFilterable={true}
                                                isSortable={true}
                                                dataName={"derp"}
                                                tableData={[selectedSearchInfo]}
                                                cellOnClick={event => {}}
                                                rowOnClick={(
                                                    rowIndex,
                                                    rowData
                                                ) => {
                                                    console.log(
                                                        'Rowonclick triggered :: ',
                                                        rowIndex,
                                                        rowData
                                                    );
                                                }}
                                            ></Table>
                                        </div>
                                    )}
                            </Tabs>
                        )
                    }
                </Content>
            </div>
        </>
    );
}

export default APIDashboard;
