
import React from "react";
import ReactDOM from "react-dom/client";
import store from './redux/store';
import { Provider } from 'react-redux';
// import "./pico.css";
import "./utilities.css";
import "./index.css";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
